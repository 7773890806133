/* App-wide styles */
.App {
  text-align: center;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;  /* Ensure default text color is dark */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background-color: white; /* Ensures the background is white */
}

/* Header styling */
.App-header {
  background-color: #fff;
  color: black; /* Explicitly set text color to black for contrast */
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
}

/* Navigation links styling */
.nav-link {
  color: black;
  padding: 10px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #555;
}


/* Main content area */
.hero {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: black; /* Ensure text inside hero is visible */
}

/* Call to action button styling */
.cta-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adds space between buttons */
  margin-top: 20px;
}

.cta-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;
}

.cta-button:hover {
  background-color: #333;
  transform: scale(1.05);
}
/* Footer styling */
footer {
  padding: 20px;
  color: #888;
  background-color: #f9f9f9; /* Light grey background to differentiate from main content */
}
