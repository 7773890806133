.Terms {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Terms-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #000000;

}

.logo {
    color: #000000;
}

.Terms-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

}

.Terms-content h1 {
  margin-bottom: 1rem;
}

.Terms-footer {
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #000000;
}

.footer-columns {
  display: flex;
  justify-content: center;

}

.back-to-home {
  color: #000000;
}
.footer-bottom {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.Terms-content ul {
  list-style-position: inside;
}

.logo-main {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
/*  font-size: 1.5rem;*/
/*  font-weight: bold;*/
  margin: 0;
}

.logo-img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
