/* Reset styles */
* {

    box-sizing: border-box;
}

body, html {
    font-family: 'Arial', sans-serif;
    width: 100%;
    height: 100%; /* Ensure body takes full viewport height */
    overflow-x: hidden; /* Hide horizontal scroll */
    scroll-behavior: smooth; /* Smooth scrolling */
    background: #f4f4f4; /* Light gray background */
}

/* Container should fill the viewport */
.container {
    min-height: 100vh; /* New: make sure container fills the screen height */
    /* max-width: 1200px; */
    margin: 0 auto;
    /* padding: 20px; */
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* New: ensures header, main, footer are spaced out */
}

.header {
    display: flex;
    align-items: center; /* Centers items vertically */
    justify-content: space-between;
    padding: 10px 20px; /* Reduced padding for a slimmer look */
    background-color: #333333; /* Unified dark grey */
    color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.logoMain {
    font-size: 24px;
    font-weight: bold; /* Ensure logo text is bold */
}

.navLinks {
    display: flex;
    align-items: center; /* Ensure links are aligned in the center vertically */
    height: 100%; /* Match the height of the header */
}

.navLinks div {
    display: inline-block; /* Change display to inline-block */
    margin-right: 20px; /* Space between navigation items */
    color: white;
    font-weight: bold; /* Ensure navigation text is bold */
    text-decoration: none; /* No underline for links */
}



.navLinks a {
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 20px; /* Larger padding for better touch area */
    font-weight: 500; /* Normal weight for a clean look */
    transition: color 0.3s; /* Smooth transition for hover effect */
  }
  
  .navLinks a:hover {
    color: #DDD; /* Less white color on hover */
    text-decoration: none; /* No underline on hover */
  }



.downloadHeader {
    font-size: 24px;
    margin-bottom: 20px;
}

.title {
    font-size: 30px; /* Slightly larger for emphasis */
    font-weight: 600; /* Bold but not too bold */
}

/* Main content styles */
.main {
    flex-grow: 1; /* Allows main to fill available space */
    padding: 2rem;
    text-align: center;
}

.section {
    background-color: #e2e2e2;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}
.ctaButton {
    background-color: #4facfe;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.downloadSection {
    background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    color: white;
    text-align: center;
    padding: 30px 0;
}

.storeIcon {
    width: 200px;
    height: auto;
    transition: transform 0.3s ease; /* Smooth transition for the transform effect */
}

.storeIcon:hover {
    transform: scale(1.1); /* Increase the size of the icon by 10% on hover */
}

.appDownload a {
    display: inline-block;
    margin: 0 10px;
}


/* Footer styles */
.footer {
    background-color: #333333; /* Unified dark grey */
    color: white;
    padding: 20px;
    text-align: center;
}

.footerColumns {
    display: flex;
    justify-content: space-around;
}

.footerColumn {
    flex: 1;
}

.footerBottom {
    margin-top: 20px;
}

.footerColumn div {
    padding: 5px 0;
}



.footerColumn ul {
    list-style: none; /* Remove bullet points */
    padding: 0; /* Reset padding, if necessary */
    margin: 0; /* Reset margin, if necessary */
}

.footerColumn li {
    margin-bottom: 10px; /* Add space between list items */
}

.footerColumn a {
    color: inherit; /* Inherit the text color from the parent */
    text-decoration: none; /* Remove underline from links */
}

.footerColumn a:hover {
    text-decoration: underline; /* Optional: underline on hover for better user experience */
}












/* Ensure footer links have no underlines and are white */
.footer a {
    color: inherit;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}
  

.footer-column ul {
    list-style: none;
    padding: 0;
  }

  /* footer a {
    color: #f8f9fa;
    text-decoration: none;
  } */

  .footer-column li {
    margin-bottom: 0.5rem;
  }
  

  .featuresList {
    padding-left: 20px;  /* Adjust this value as needed to control space from the left edge */
    list-style-position: inside; /* Moves the bullet point inside the content flow, closer to text */
}

.featuresList li {
    margin-bottom: 10px; /* Adjust space between list items */
}